.ImgContainer{
    flex:1;
    height:1500px;
    position: relative;
    align-items:center;
    justify-content:center;
    text-align: center;
    border-bottom: 1px solid #CCC;
    border-top: 1px solid #CCC;
    overflow: hidden;
}
.imgBackground{
    opacity: 0.2;
}
.logo{
    margin-top:10px;
    align-self: center;
    opacity: 1;
    min-width: 260px;
    max-width: 500px;
    width:50%;
}
.layer1{
    position: absolute;
    width:100%;
    height:100%;
    background-color: rgba(210, 210, 211, 0.1);
}

.parallax{    
    display:flex;
    justify-content: space-between;    
    min-height: 400px;
}

.textContainer h1{
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: bold;
    font-size: 1.4em;
    margin-left: 20px;
    width: 100%;
    text-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, .20);
}

.grey{
    color: #949391;
    text-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, .20);
}

.historia p{
    font-size: 1.4em;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
}
.historia2 p{
    font-size: 1.4em;
    margin-right: 20px;
    margin-left: 20px;
}

.background{
    filter: blur(8px);
    -webkit-filter: blur(8px);
}

.historiaFigure{
    width: 100%;
    margin-top: 10px;
    text-align: center;
    color: #949391;
    font-family: 'Roboto Condensed', sans-serif;
}

.figure1{
    width:100%;
}
@media only screen and (max-width: 320px) {
    .ImgContainer{
        height:1500px;
    }
    .parallax{     
        min-height: 0px;
    }
    .imgBackground{
        width:120%;
    }
    .logo{
        min-width: 230px;
        max-width: 300px;
        margin-left: 15px;
    }
    .textContainer{
        width: 95%;
        margin: auto;
        margin-bottom: -60px;
    }
    .textContainer h1{
        width: 100%;
        margin-top: 0px;
        margin-bottom: 10px;
        font-size: 1em;
        margin-left: 0px;
    }
    .historia{
        width:100%;
        text-align: justify;
    }
    .historia2{
        width:100%;
        text-align: justify;
    }
    .historia p, .historia2 p{
        font-size: 1em;
    }
    .smallParallaxImg{
        opacity: 0.4;        
        max-height: 290px;
        margin-top: 20px;
    }
}

@media only screen and (min-width: 321px) and (max-width: 450px) {
    .ImgContainer{
        height:1500px;
    }
    .parallax{     
        min-height: 0px;
    }
    .imgBackground{
        width:120%;
    }
    .logo{
        min-width: 230px;
        max-width: 300px;
        margin-left: 15px;
    }
    .textContainer{
        width: 95%;
        margin: auto;
        margin-bottom: -60px;
    }
    .textContainer h1{
        width: 100%;
        margin-top: 0px;
        margin-bottom: 10px;
        font-size: 1em;
    }
    .historia{
        width:100%;
        text-align: justify;
    }
    .historia2{
        width:100%;
        text-align: justify;
    }
    .historia p, .historia2 p{
        font-size: 1em;
    }
    .smallParallaxImg{
        opacity: 0.4;        
        max-height: 290px;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 760px) {
    .ImgContainer{
        height:1500px;
    }
    .parallax{     
        min-height: 0px;
    }
    .imgBackground{
        width:150%;
    }
    .logo{
        min-width: 240px;
        max-width: 350px;
    }
    .textContainer{
        width: 95%;
        margin: auto;
        margin-bottom: -60px;
    }
    .textContainer h1{
        width: 100%;
        margin-top: -20px;
        margin-bottom: 10px;
    }
    .historia{
        width:100%;
        text-align: justify;
    }
    .historia2{
        width:100%;
        text-align: justify;
    }
    .smallParallaxImg{
        opacity: 0.4;
    }
    .historiaFigure{
        font-size: 0.7em;
    }
    
    .parallaxRightDiv{
        margin-top: 40px;
    }
}

@media screen and (orientation:portrait) and (max-width: 1030px) and (min-width: 761px){
    .ImgContainer{
        height:2350px;
    }
    .parallax{     
        min-height: 0px;
    }
    .imgBackground{
        width:150%;
    }
    .logo{
        min-width: 240px;
        max-width: 350px;
        margin-top: -40px;
    }
    .textContainer{
        align-content: space-around;
        text-align: justify;
        width: 75%;
        margin: 0 auto;
        font-size: 1.2em;
    }
    .textContainer h1{
        width: 100%;
        margin-top: -30px;
        margin-bottom: 10px;
        margin-left: 0px;
        font-size: 1.5em;
    }
    .historia{
        width:100%;
        text-align: justify;
        max-width: 100%;
        margin-left: 0px;
    }
    .historia2{
        width:100%;
        text-align: justify;
        max-width: 100%;
    }
    .historia p, .historia2 p{
        width:100%;
        max-width: 100%;
        margin-left: 0px;
    }
    .smallParallaxImg{
        opacity: 0.4;
    }
    .parallaxLeftDiv{
        margin-top: -70px;
    }
}

@media screen and (orientation:portrait) and  (min-height: 1300px){
    .ImgContainer{
        height:2300px;
    }
    .parallax{     
        min-height: 0px;
    }
    .imgBackground{
        width:150%;
    }
    .logo{
        min-width: 240px;
        max-width: 350px;
        margin-top: -40px;
        margin-left: -30px;
    }
    .textContainer{
        display: flex;
        align-content: space-around;
        text-align: justify;
        width: 100%;
        margin: 0 auto;
        padding-left: 40px;
        font-size: 1.2em;
        flex-wrap: wrap;
    }
    .textContainer h1{
        width: 100%;
        text-align: center;
        margin-bottom: 40px;
        margin-left: 0px;
        font-size: 1.7em;
    }
    .historia{
        width:100%;
        text-align: justify;
        max-width: 100%;
        margin-left: 0px;
    }
    .historia2{
        width:100%;
        text-align: justify;
        max-width: 100%;
    }
    .historia p, .historia2 p{
        width:100%;
        max-width: 95%;
        margin-left: 0px;
        font-size: 1.3em;
        margin-bottom: 20px;
    }
    .smallParallaxImg{
        opacity: 0.4;
    }
}

@media screen and (orientation:landscape) and (max-device-height:1000px){
    .ImgContainer{
        height:1200px;
    }
    .parallax{     
        min-height: 0px;
    }
    .imgBackground{
        width:150%;
    }
    .logo{
        min-width: 240px;
        max-width: 350px;
        transform: scale(1) !important;
    }
    .textContainer{
        display: flex;
        align-content: space-around;
        text-align: justify;
        width: 90%;
        margin: 0 auto;
        margin-top: -30px;
        flex-wrap: wrap;
    }
    .textContainer h1{
        width: 100%;
        margin-bottom: 10px;
        margin-left: 0px;
        font-size: 1.5em;
    }
    .historia{
        width:100%;
        text-align: justify;
        margin-left: 0px;
    }
    .historia2{
        width:50%;
        text-align: justify;
    }
    
    .historia, .historia2{
        max-width: 33%;
    }
    .historia p, .historia2 p{
        width:100%;
        max-width: 90%;
        margin-left: 0px;
        font-size: 1.2em;
    }  
    .smallParallaxImg{
        opacity: 0.4;
        max-height: 290px;
    }
    .parallaxLeftDiv{
        margin-top: -110px;
    } 
    
    .parallaxRightDiv{
        margin-top: -70px;
    }

    .figure1{
        width:70%;
    }
}

@media only screen and (max-width: 2400px) and (min-width: 1200px) {   
    .ImgContainer{
        height:1500px;
    } 
    .logo{
        min-width: 240px;
        max-width: 350px;
        transform: translate(-50px);
    }
    .parallaxLeft{
        height: 90%;
        width: 80%;
    }
    .parallaxRight{
        height: 90%;
        width: 70%;
    }
    
    .textContainer{
        display: flex;
        align-content: space-around;
        text-align: justify;
        width: 90%;
        margin: 0 auto;
        margin-top: 60px;
        flex-wrap: wrap;
    }
    .textContainer{
        margin-bottom: 30px;
    }
    .historia, .historia2{
        max-width: 33%;
    }
    .historia p, .historia2 p{
        font-size: 1.2em;
    }
    
    .figure1{
        width:50%;
    }
}

@media only screen and (min-width: 2400px) {      
    .ImgContainer{
        height:1500px;
    } 
    .textContainer{
        display: flex;
        align-content: space-between;
        text-align: justify;
        width: 70%;
        padding-top: 100px;
        margin: auto;
        flex-wrap: wrap;
    }
    .historia, .historia2{
        max-width: 33%;
    }
    .historia p, .historia2 p{        
        max-width: 90%;
    }
    .figure1{
        width:50%;
    }
}