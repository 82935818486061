.navbar.center .navbar-inner {
    text-align: center;
}

.navbar.center .navbar-inner .nav {
    display:inline-block;
    float: none;
}

.navbar-light .navbar-nav .nav-link {
    font-size: 180%;
}

.active{
    color:red;
}

@supports  (-webkit-overflow-scrolling: touch) {
    .ios{
        display: none !important;
    }
    .nonIos{
        display: inline-block !important;
    }
}
@supports not (-webkit-overflow-scrolling: touch) {
    .nonIos{
        display: none !important;
    }
    .ios{
        display: inline-block !important;
    }
}