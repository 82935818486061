.EMTList{
  margin-top: 30px;
  text-align: start;
  list-style-type: square; 
  font-size:120%;
  line-height: 140%;
  color: #444;
}

.EMTList li{
  margin-bottom: 10px;
}

.EMT p{
  color: #555;
  font-weight: normal;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.5em;
  text-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, .20);
}

.EMT h1{
  margin-bottom: 50px;
  color: #949391;
  font-size: 3em;
  font-weight: bolder;
  text-align: center;
  text-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, .20);
}

.margin-block-left{
  margin-left:7%;;
}

.margin-block-top{
  margin-top:30px;
}

.big-padding-spacer{
  padding-top:50px;
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, .05);
}
.box-shadow2 {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, .20);
}
.cursor-link{
  cursor:pointer;
}
.emtFooter, .emtFooterImg{
  width: 100%;
}

@media only screen and (max-width: 320px) {
    .EMT h1{
      font-size: 1.5em;
      margin-bottom: 20px;
      text-align: center;
      max-width: 320px;
    }
    .EMT p{      
      font-size: 1em;
    }
}

@media only screen  and (min-width: 321px) and (max-width: 376px) {
  p{
    text-align: justify;
  }
  .margin-block-left{
    margin-left:0px;
  }
  .big-padding-spacer{
    padding-top:20px;
  }
  h1,h2{
    margin-left:0px !important;
  }
  .EMT p{      
    font-size: 1em;
  }
  .EMT h1{
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;
    max-width: 375px;
  }
}

@media only screen  and (min-width: 376px) and (max-width: 600px) {
    p{
      text-align: justify;
    }
    .margin-block-left{
      margin-left:0px;
    }
    .big-padding-spacer{
      padding-top:20px;
    }
    h1,h2{
      margin-left:0px !important;
    }
    .EMT p{      
      font-size: 1em;
    }
    .EMT h1{
      font-size: 1.6em;
      margin-bottom: 20px;
      text-align: center;
    }
}

@media screen and (orientation:landscape) and (max-device-height:1000px){
  .EMT p{
    font-size: 1.2em;
  }
}  

@supports not (-webkit-overflow-scrolling: touch) {
  .ios{
      display: none !important;
  }
  .nonIos{
      display: inline-block !important;
  }
}
@supports (-webkit-overflow-scrolling: touch) {
  .nonIos{
      display: none !important;
  }
  .ios{
      display: inline-block !important;
  }
}