.quick-contact {
    color:#CCC;
    background-color: #111;
    box-shadow: 0px 8px 6px -6px rgba(68, 68, 68, 0.4);
}
.quick-contact a, .quick-contact button {
    color:#CCC;
    margin: 0px;
    margin-right: 5px;
    font-family: Arial, Helvetica, sans-serif;
    font-stretch: extra-expanded;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0px;
}
.highlight{
    margin-left:10px;
}

.highlight:hover, .quick-contact a:hover{
    color:white;
    text-decoration: none;
}


@media screen and (max-width: 320px) {
    .sticky-top {
        font-size: 0.7em;
    }
}